import React, {useContext, useEffect, useState} from 'react';
import './Home.css'
import CardServices from "../../components/CardServices/CardServices";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import {ContentContext} from "../../App";
import ReactInput from "input-format/react";
import { templateParser, templateFormatter, parseDigit } from 'input-format'
const TEMPLATE = '+x (xxx) xxx-xx-xx'
const parse = templateParser(TEMPLATE, parseDigit)
const format = templateFormatter(TEMPLATE)

const Home = () => {

    const [phone, setPhone] = useState('')
    const [sended, setsSended] = useState(false)
    const [empty, setEmpty] = useState(false)
    const [error, setError] = useState(false)
    const [checkBox, setCheckBox] = useState(false)
    const [textBtn, setTextBtn] = useState('Отправить заявку')


    const check = ()=>{
        setCheckBox(document.getElementById('home__checkbox').checked)
    }



    useEffect(() => {
        if (phone.length === 1 && phone !== '7'){
            setPhone( '7' + phone)
        }
    }, [phone]);

    useEffect(() => {
        let mobilePipeDeg = 0;
        let lastScrollTop = 0;
        let mobilePipeValve = document.getElementById('mobile-pipe-valve');
        if(mobilePipeValve != null){
            document.addEventListener("scroll", (event) => {
                let st = window.scrollY || document.documentElement.scrollTop;
                if (st > lastScrollTop) {
                    mobilePipeValve.style.transform = 'rotate(' + mobilePipeDeg + 'deg)';
                    mobilePipeDeg = mobilePipeDeg + 2;
                }
                else if (st < lastScrollTop) {
                    mobilePipeValve.style.transform = 'rotate(' + mobilePipeDeg + 'deg)';
                    mobilePipeDeg = mobilePipeDeg - 2;
                }
                lastScrollTop = st <= 0 ? 0 : st;
            });
        }

    }, [])

    useEffect(() => {
        if(sended)setTextBtn('Заявка отправлена!')
        if(error) setTextBtn('Упс! Что то пошло не так..')
        if(empty) setTextBtn('Поля не заполнены!')
        if(!empty && !error && !sended) setTextBtn('Отправить заявку');
    }, [empty,error,sended]);

    const sendHandler = (e)=>{
        e.preventDefault()
        if(!sended){
            if (
                document.getElementById(
                    'home_input1').value === ''
                ||document.getElementById(
                    'home_input2').value === ''
                ||document.getElementById(
                    'home_input3').value === ''
                ||document.getElementById(
                    'home_input4').value === ''
                ||document.getElementById(
                    'home_input5').value === ''
                ||  phone === ''
            ){
                setEmpty(true)
                setPhone('')
                document.getElementById('home_input1').value = ''
                document.getElementById('home_input2').value = ''
                document.getElementById('home_input3').value = ''
                document.getElementById('home_input4').value = ''
                document.getElementById('home_input5').value = ''
                setTimeout(()=>{
                    setEmpty(false)
                },2000)
            }else{
                fetch('https://dk-profi.com/api/mailer.php?' +
                    'address=' + document.getElementById('home_input1').value +
                    '&distance=' + document.getElementById('home_input2').value +
                    '&insert_place=' + document.getElementById('home_input3').value +
                    '&name=' + document.getElementById('home_input4').value +
                    '&email=' + document.getElementById('home_input5').value +
                    '&phone=' + phone + '&action=HomeForm' ,
                    {
                        headers: {
                            'Accept': 'application/x-www-form-urlencoded;charset=UTF-8'
                        }
                    })
                    .then(response => response.text())
                    .then((val)=>{
                        if(val === 'ok'){
                            setsSended(true)
                        }else {
                            setError(true)
                            setTimeout(()=>{
                                setError(false)
                            },3000)
                        }
                    })
            }
        }
    }


    let sliderSize
    let sliderPerPage
    if (window.screen.width < 800 ){
        sliderPerPage = 1
    }else if(window.screen.width < 1200){
        sliderPerPage = 2
    }
    else{
        sliderPerPage = 3
    }

    let homeBannerSrc;
    if (window.screen.width < 500 ){
        homeBannerSrc = ""
    }
    else{
        homeBannerSrc = "img/banner_big.mp4"
    }

    const content = useContext(ContentContext);



    return (
        <main className={'home'}>
            <section className="section-banner">

                <div className="section-banner__banner">
                    <span className="section-banner__pipe-banner"></span>
                    <div className="section-banner__mobile-top-img"></div>
                    <div className="section-banner__mobile-bottom-img"></div>
                    <video id="home_banner_video" autoPlay muted loop playsInline className="section-banner__video-banner">
                        <source src={homeBannerSrc} />
                    </video>
                </div>

                <div className="content">
                    <div className="section-banner__content">
                        <div>
                            <h1>ВЫГОДНАЯ ГАЗИФИКАЦИЯ <br/> ДОМОВ ПОД КЛЮЧ</h1>
                            <ul>
                                <li> - Подготовка проекта</li>
                                <li> - Получение технических условий</li>
                                <li> - Согласование на всех стадиях</li>
                                <li> - Монтажные работы</li>
                            </ul>
                            <a className="section-banner__btn" href="/services">Узнать стоимость</a>
                        </div>
                        <div className="section-banner__paragraph-list">
                            <div><h5>ЧАСТНЫЕ ДОМА</h5><p>Мы оказываем комплексные
                                услуги по газификации частных
                                и многоквартирных домов — от
                                получения технических условий
                                до пуска газа. Выполняем техно-
                                логическое присоединение до
                                границ земельного участка и по
                                его территории </p></div>
                            <div><h5>ТАУНХАУСЫ</h5><p>Выполняем комплекс работ по
                                прокладке стального наружного
                                газопровода по фасадам
                                и внутреннего — в помещении
                                кухни и котельной, включая
                                установку приборов учёта,
                                запорной арматуры, грунтовку
                                и покраску всех труб</p></div>
                            <div><h5>КОТТЕДЖНЫЕ ПОСЕЛКИ</h5><p>Обеспечиваем газификацию
                                коттеджных поселков,
                                проектирование и строительство
                                подводящих, распределительных,
                                внутриплощадочных и внутренних
                                газопроводов</p></div>
                            <div><h5>ПРОМЫШЛЕННЫЕ ОБЪЕКТЫ</h5><p>Одним из ключевых направлений
                                деятельности нашей компании является
                                газификация организаций и предприятий.
                                Выполняем монтажные работы и оперативно
                                сдаем объект Мособлгаз</p></div>
                        </div>
                    </div>
                </div>

            </section>

            <section id="about" className="section-about">
                <div className="content">
                    <div className="section-about__title">
                        <div>
                            <h3>РЕШАЕМ СЛОЖНЫЕ <br/> ЗАДАЧИ</h3>
                            <h2>Мы имеем все необходимые лицензии на данный вид деятельности и допуски СРО. Наши
                                специалисты
                                аттестованы в «Ростехнадзоре» и являются высококвалифицированным профессионалами.</h2>
                        </div>
                        <span className="section-about__pipe"></span>
                    </div>
                    <div className="section-about__paragraph-list">
                        <div>
                            <h3>ИНТЕГРИРОВАННЫЙ ПОДХОД</h3>
                            <p>Мы предоставляем комплексные решения от начального проектирования до введения в
                                эксплуатацию, что позволяет нашим клиентам избежать необходимости обращаться к
                                нескольким подрядчикам.</p>
                        </div>
                        <div>
                            <h3>ВЫСОКИЙ УРОВЕНЬ ПРОФЕССИОНАЛИЗМА</h3>
                            <p>Наш коллектив состоит из опытных специалистов, готовых решать любые технические и
                                организационные задачи, связанные с газификацией.</p>
                        </div>
                        <div>
                            <h3>СОБЛЮДЕНИЕ СРОКОВ И СТАНДАРТОВ</h3>
                            <p>Мы гарантируем выполнение работ в строго установленные сроки и с соблюдением всех
                                нормативных требований и стандартов качества.</p>
                        </div>
                        <div>
                            <h3>ТЕХНОЛОГИЧЕСКАЯ КОМПЕТЕНТНОСТЬ</h3>
                            <p>Мы используем современное оборудование и передовые технологии, что обеспечивает
                                надежность и безопасность наших газопроводов.</p>
                        </div>
                    </div>
                    <div className="mobile-pipe-long">
                        <img alt="Труба" src="/img/mobile-troobs.png"/>
                        <div id="mobile-pipe-valve" className="mobile-pipe-valve"></div>
                    </div>
                </div>
            </section>

            <section id="coasts" className="section-coasts">
                <div className="content">
                    {/*<div className="card ">*/}
                    {/*    <h4 className="card__title card__substrate">*/}
                    {/*        Проектирование*/}
                    {/*    </h4>*/}
                    {/*    <p><span className="card__round"></span>Бесплатный выезд специалиста*/}
                    {/*        и консультации</p>*/}
                    {/*    <p><span className="card__round"></span>Составление задания на проектирование*/}
                    {/*        с учетом ваших пожеланий</p>*/}
                    {/*    <p><span className="card__round"></span>Разработка проектной документации</p>*/}
                    {/*    <p><span className="card__round"></span>Согласование проектной документации</p>*/}
                    {/*    <div className="card__coast card__substrate">*/}
                    {/*        от 20 000 ₽*/}
                    {/*    </div>*/}
                    {/*    <div className="card__btn card__substrate">*/}
                    {/*        <p>Заказать услугу</p>*/}
                    {/*        <span className="card__arrow"></span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="card card_large">*/}
                    {/*    <h4 className="card__title card__substrate">*/}
                    {/*        Строительство*/}
                    {/*    </h4>*/}
                    {/*    <p><span className="card__round"></span>Получение необходимых разрешений</p>*/}
                    {/*    <p><span className="card__round"></span>Организация строительной площадки</p>*/}
                    {/*    <p><span className="card__round"></span>Закупка, доставка оборудования*/}
                    {/*        и материалов</p>*/}
                    {/*    <p><span className="card__round"></span>Строительно-монтажные работы*/}
                    {/*        наружного/подземного газопровода*/}
                    {/*    </p>*/}
                    {/*    <p><span className="card__round"></span>Строительно-монтажные работы*/}
                    {/*        внутреннего газопровода*/}
                    {/*    </p>*/}
                    {/*    <p><span className="card__round"></span>Разработка и согласование исполнительно -*/}
                    {/*        технической документации*/}
                    {/*    </p>*/}
                    {/*    <div className="card__coast card__substrate">*/}
                    {/*        от 100 000 ₽*/}
                    {/*    </div>*/}
                    {/*    <div className="card__btn card__substrate">*/}
                    {/*        <p>Заказать услугу</p>*/}
                    {/*        <span className="card__arrow"></span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="card ">*/}
                    {/*    <h4 className="card__title card__substrate">*/}
                    {/*        Строительный контроль*/}
                    {/*    </h4>*/}
                    {/*    <p><span className="card__round"></span>Приемка строительных материалов</p>*/}
                    {/*    <p><span className="card__round"></span>Выявление нарушений в хранении*/}
                    {/*        и складировании строительных материалов</p>*/}
                    {/*    <p><span className="card__round"></span>Контроль сроков выполнения работ,*/}
                    {/*        согласно календарному плану*/}
                    {/*    </p>*/}
                    {/*    <p><span className="card__round"></span>Проверка наличия необходимых*/}
                    {/*        сертификатов и документов*/}
                    {/*        на строительные материалы*/}
                    {/*    </p>*/}
                    {/*    <div className="card__coast card__substrate">*/}
                    {/*        от 10 000 ₽*/}
                    {/*    </div>*/}
                    {/*    <div className="card__btn card__substrate">*/}
                    {/*        <p>Заказать услугу</p>*/}
                    {/*        <span className="card__arrow"></span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <h2 className="section-about__2th-title">УСЛУГИ</h2>
                    <Splide className="services-slider" aria-label="My Favorite Images"
                            options={{
                                perPage: sliderPerPage,
                                type: 'loop',
                                perMove: 1,
                                gap: 10,
                                breakpoints: {
                                    1210: {
                                        perPage: 2,
                                    },
                                    767: {
                                        perPage: 2,
                                    },
                                    640: {
                                        perPage: 1,
                                    },
                                }
                            }}
                    >
                        {content.services.map((value, index) => {

                            return (<SplideSlide key={"card_home_" + index}>
                                <CardServices
                                    title={value.name}
                                    cost={value.cost}
                                    image={value.img}
                                    description={value.desc_short}
                                    description_long={value.desc_long}
                                    id={value.id}
                                />
                            </SplideSlide>)

                        })}
                    </Splide>


                </div>
            </section>

            <section id="calc" className="section-calc">
                <div className="content">
                    <div className="section-calc__content">
                        <h3 className="section-calc__title">
                            ЭТАПЫ И ПОРЯДОК ГАЗИФИКАЦИИ <br/>
                            ЧАСТНОГО ДОМА В МОСКОВСКОЙ <br/>
                            ОБЛАСТИ
                        </h3>
                        <div className="section-calc__grid">

                            <div className="section-calc__card">
            <span className="section-calc__card__round">
              1
            </span>
                                <div className="section-calc__card__content">
                                    <h4>Получаем договоры о подключении
                                        с техническими условиями</h4>
                                    <h5>Вначале мы формируем пакет документов для получения ТУ
                                        и подаем заявление на получение технических условий
                                        в газораспределительную организацию. Получаем ТУ
                                        и заключаем договоры технологического присоединения с ГРО.</h5>
                                </div>
                            </div>

                            <div className="section-calc__card">
            <span className="section-calc__card__round">
              2
            </span>
                                <div className="section-calc__card__content">
                                    <h4>Осуществляем расчет по проекту
                                        и выполняем все строительно-
                                        монтажные работы</h4>
                                    <h5>Далее проводим работы по монтажу газопровода, включая
                                        земляные работы и монтаж техноборудования.</h5>
                                </div>
                            </div>

                            <div className="section-calc__card">
            <span className="section-calc__card__round">
              3
            </span>
                                <div className="section-calc__card__content">
                                    <h4>Получаем договоры на ТО ВДГО
                                        и договоры на поставку газа</h4>
                                    <h5>Заключаем договоры на техническое обслуживание,
                                        поставку газа и пуско-наладочные работы. Договоры
                                        заключаются с филиалом АО «Мособлгаз» при
                                        направлении соответствующего заявления
                                        с необходимым пакетом документов.</h5>
                                </div>
                            </div>

                            <div className="section-calc__card">
            <span className="section-calc__card__round">
              4
            </span>
                                <div className="section-calc__card__content">
                                    <h4>Проектируем, согласовываем
                                        и регистрируем проект</h4>
                                    <h5>Затем выполняем проектные работы — от
                                        точки подключения до газоиспользующего
                                        оборудования. Согласуем проект газификации
                                        со всеми заинтересованными организациями.</h5>
                                </div>
                            </div>

                            <div className="section-calc__card">
            <span className="section-calc__card__round">
              5
            </span>
                                <div className="section-calc__card__content">
                                    <h4>Сдаем построенный
                                        газопровод в эксплуатацию</h4>
                                    <h5>Собираем исполнительно-техническую документацию.
                                        Составляем акты технической приемки работ, а также
                                        документы на газоиспользующее и технологическое
                                        оборудование.</h5>
                                </div>
                            </div>

                            <div className="section-calc__card">
            <span className="section-calc__card__round">
              6
            </span>
                                <div className="section-calc__card__content">
                                    <h4>Сопровождаем объект
                                        до врезки и пуска газа</h4>
                                    <h5>Назначаем даты врезки и пуска газа. При пуске
                                        газа обязательно должен присутствовать абонент,
                                        поэтому заранее оговариваются дата и время, когда
                                        газовики смогут прибыть и выполнить мероприятие
                                        по фактическому присоединению</h5>
                                </div>
                            </div>


                        </div>

                        <form className="form">
                            <h3 className="form__title">Получите расчёт стоимости
                                подключения газа</h3>
                            <p className="form__description">Ответьте на несколько вопросов, и мы рассчитаем стоимость
                                подключения газа к вашему дому.</p>
                            <input id={'home_input1'} className="form_input-text" type="text" placeholder="Адрес объекта"/>
                            <input id={'home_input2'} className="form_input-text" type="text"
                                   placeholder="Растояние до газовой магистрали (м)"/>
                            <input id={'home_input3'} className="form_input-text" type="text" placeholder="Место врезки в газопровод"/>
                            <input id={'home_input4'} className="form_input-text" type="text" placeholder="Имя"/>
                            <input id={'home_input5'} className="form_input-text" type="text" placeholder="E-mail"/>
                            <ReactInput
                                className="form_input-text"
                                value={phone}
                                onChange={setPhone}
                                parse={parse}
                                format={format}
                                placeholder={'Номер телефона'}
                                inputMode={'tel'}
                            />
                            <div className="form__policy-adoption">
                                {/*<input onClick={check} id={'home__checkbox'} type="checkbox" name="0" />*/}
                                <p className="form__policy">Нажимая кнопку «Отправить», я даю свое согласие на обработку
                                    моих
                                    персональных данных, в соответствии с Федеральным законом от 27.07.2006 года
                                    №152-ФЗ «О персональных данных», на условиях и для целей, определенных в
                                    Согласии на обработку персональных данных*</p>
                            </div>
                            <button onClick={sendHandler}>{textBtn}</button>
                        </form>

                    </div>
                    <span className="pipe-valve"></span>
                    <span className="pipe-long"></span>
                </div>
            </section>
        </main>
    );
};



export default Home;