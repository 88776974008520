import React, {useContext} from 'react';
import './Footer.css'
import {NavLink} from "react-router-dom";
import {ContentContext} from "../../App";

const Footer = () => {
    const setActive = ({isActive})=> isActive ? "footer__content-box__link footer__content-box___link_active" : 'footer__content-box__link'

    const content = useContext(ContentContext);
    const scrollTop= ()=>{
        window.scrollTo(0, 0)
    }

    return (
        <footer>
            <div className="content">
                <div className="footer__logo-box">
                    <a href="/" className="logo_black"></a>
                    <p>© 2023 All rights reserved.</p>
                </div>
                <div className="footer__content-box">
                    <nav>
                        <NavLink onClick={scrollTop} to={"/"} className={setActive}>Главная</NavLink>
                        <NavLink onClick={scrollTop} to={"/about"} className={setActive}>О компании</NavLink>
                        <NavLink onClick={scrollTop} to={"/services"} className={setActive}>Услуги</NavLink>
                        <NavLink onClick={scrollTop} to={"/portfolio"} className={setActive}>Портфолио</NavLink>
                        <NavLink onClick={scrollTop} to={"/contacts"} className={setActive}>Контакты</NavLink>
                        <a href="/" className={'download_link'}>Политика конфиденциальности</a>
                    </nav>
                    <div className="footer__contact-list">

                        <div>
                            <p>Телефон:</p>
                            <p>{content.phone}</p>
                        </div>

                        <div>
                            <p>Почта:</p>
                            <p>{content.email}</p>
                        </div>

                        <div>
                            <p>Режим работы:</p>
                            <p>{content.work_time}</p>
                        </div>

                        <div><p className={'footer__address'}>
                            {
                                content.address
                            }
                        </p></div>

                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;